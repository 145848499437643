'use client';

import { useContext, useEffect } from 'react';

import { Context } from './Context';
import { useGetVenues } from '@/hooks/events';

import { VenuesReducerActionType } from './Types';

export const Handler = () => {
  const { context, dispatch } = useContext(Context);
  const { fetchItems: getVenues } = useGetVenues({ skipFetch: true });

  const getItems = async () => {
    const venues = await getVenues({
      ...(context?.filters ?? {}),
    });

    dispatch({
      type: VenuesReducerActionType.updateVenues,
      payload: venues,
    });
  };

  useEffect(() => {
    getItems();
  }, [context?.filters]);

  return null;
};
