import { IComponentHeader } from '@/components/Header/Header';
import { IComponentCardBasic } from '@/components/CardBasic/CardBasic';
import { IComponentEventsList } from '@/components/EventsList/EventsList';
import { IComponentVenuesList } from '@/components/VenuesList/VenuesList';

import { ComponentType } from '@lamesarv-sdk/types';

export enum CustomComponentType {
  header = 'header',
  eventsList = 'eventsList',
  venuesList = 'venuesList',
  cardBasic = 'CardBasic',
}

export const componentConverters = {
  header: ({ fields: { title, subtitle, classes, image_url, ...extraData } }): IComponentHeader => ({
    componentType: CustomComponentType.header as unknown as ComponentType,
    title,
    subtitle,
    backgroundUrl: image_url,
    className: classes,
    extraData,
  }),
  events_list: ({
    fields: {
      filter_by_state,
      filter_by_city,
      filter_by_venue,
      display_search,
      display_filter_state,
      display_filter_city,
      display_filter_venue,
      display_events_amount,
      classes,
      classes_header,
      classes_navigation,
      classes_list,
      ...extraData
    },
  }): IComponentEventsList => ({
    componentType: CustomComponentType.eventsList as unknown as ComponentType,
    filterByState: filter_by_state,
    filterByCity: filter_by_city,
    filterByVenue: filter_by_venue,
    displaySearch: display_search,
    displayFilterState: display_filter_state,
    displayFilterCity: display_filter_city,
    displayFilterVenue: display_filter_venue,
    displayEventsAmount: display_events_amount,
    className: classes,
    classHeader: classes_header,
    classNavigation: classes_navigation,
    classList: classes_list,
    extraData,
  }),
  venues_list: ({
    fields: {
      filter_by_state,
      filter_by_city,
      display_search,
      display_filter_state,
      display_filter_city,
      classes_header,
      classes_list,
      base_columns,
      sm_columns,
      md_columns,
      lg_columns,
      xl_columns,
      gap,
      ...extraData
    },
  }): IComponentVenuesList => ({
    componentType: CustomComponentType.venuesList as unknown as ComponentType,
    filterByState: filter_by_state,
    filterByCity: filter_by_city,
    displaySearch: display_search,
    displayFilterState: display_filter_state,
    displayFilterCity: display_filter_city,
    classHeader: classes_header,
    classList: classes_list,
    baseCols: base_columns,
    smCols: sm_columns,
    mdCols: md_columns,
    lgCols: lg_columns,
    xlCols: xl_columns,
    gap,
    extraData,
  }),
  basic_card: ({
    fields: { title, description, links, classes, classes_title, classes_description, classes_links, ...extraData },
  }): IComponentCardBasic => ({
    componentType: CustomComponentType.cardBasic as unknown as ComponentType,
    title,
    description,
    links: links.map((link) => ({
      title: link.title,
      url: link.url,
      className: link.classes,
    })),
    className: classes,
    classTitle: classes_title,
    classContent: classes_description,
    classList: classes_links,
    extraData,
  }),
};
