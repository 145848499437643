'use client';

import { SyntheticEvent, useContext } from 'react';
import { faArrowUpRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LayoutContext } from '@/contexts/LayoutContext';

import { states } from '@lamesarv-sdk/tools';

import { IVenue } from '@/types/cms';

export const GridItem = (props: IVenue) => {
  const { context } = useContext(LayoutContext);

  const statePostal = states.find((state) => state.name === props.state.toLowerCase())?.postal?.toUpperCase() ?? '';

  return (
    <a href={`/venues/${props.slug}`} className="flex flex-col gap-2 items-start rounded-sm p-6 hover:shadow-md">
      <div className="flex flex-col items-center justify-center w-full bg-gray-900 h-52">
        {props.image && <img src={props.image} alt="Venue Image" className="min-w-full h-auto max-h-52 object-cover" />}
        {!props.image && <img src={context.logoUrl} alt="Venue Image" className="w-full h-auto p-12 object-cover" />}
      </div>
      <div className="text-lg font-bold">{props.title}</div>
      <div className="text-sm font-medium">
        {props.city}, {props.state}
      </div>
      <div className="flex flex-col gap-1 text-sm text-gray-500">
        <span>
          {props.address}, {props.state}
        </span>
        <span>
          {props.city}, {statePostal} - {props.zipCode}
        </span>
      </div>
      <div
        className="flex flex-row gap-1 items-center font-medium text-sm text-blue-600 hover:text-blue-800"
        onClick={(event: SyntheticEvent) => {
          event.preventDefault();
          event.stopPropagation();
          window.open(props.map, '_blank').focus();
        }}
        onKeyDown={(event: SyntheticEvent) => {
          event.preventDefault();
          event.stopPropagation();
          window.open(props.map, '_blank').focus();
        }}
        role="link"
        tabIndex={0}
      >
        <span>Open in Maps</span>
        <FontAwesomeIcon icon={faArrowUpRight} className="w-4 h-4" />
      </div>
    </a>
  );
};
