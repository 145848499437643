import { faArrowUpRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

import { IComponentBase, PropsWithClasses } from '@lamesarv-sdk/types';
import { RichText } from '@lamesarv-sdk/components';

export interface ILink extends PropsWithClasses {
  title: string;
  url: string;
}

export interface IComponentCardBasic extends IComponentBase, PropsWithClasses {
  title: string;
  description?: string;
  links?: ILink[];
  classLinks?: string;
}

const customRemarkComponents = {
  p: ({ children }) => <p className="mb-2 leading-relaxed">{children}</p>,
};

export const CardBasic = (props: IComponentCardBasic) => {
  return (
    <div
      className={twMerge('flex flex-col gap-3 border p-8 h-full border-gray-100 rounded-md shadow-md', props.className)}
    >
      <div className={twMerge('text-xl', props.classTitle)}>{props.title}</div>
      <div className="text-sm">
        <RichText
          content={props.description}
          customRemarkComponents={customRemarkComponents}
          className={props.classContent}
        />
      </div>
      {!!props?.links?.length && (
        <div className={twMerge('', props.classLinks)}>
          {props.links.map((link) => (
            <div key={link.url} className="flex flex-row gap-1 items-center text-blue-600 group">
              <a
                href={link.url}
                className="text-xs font-medium hover:text-blue-800 hover:underline hover:underline-offset-4 hover:decoration-dashed"
                target="_blank"
              >
                {link.title}
              </a>
              <FontAwesomeIcon icon={faArrowUpRight} className="w-4 h-4" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
