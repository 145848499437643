import { VenuesReducerActionType, IVenuesContextData, IVenuesReducerAction } from './Types';

export const venuesReducer = (state: IVenuesContextData, action: IVenuesReducerAction) => {
  switch (action.type) {
    case VenuesReducerActionType.updateVenues:
      return {
        ...state,
        venues: action.payload,
      };
    case VenuesReducerActionType.updateQuery:
      return {
        ...state,
        filters: {
          ...(state?.filters ?? {}),
          query: action.payload,
        },
      };
    case VenuesReducerActionType.updateStates:
      return {
        ...state,
        filters: {
          ...(state?.filters ?? {}),
          states: action.payload,
        },
      };
    case VenuesReducerActionType.updateCities:
      return {
        ...state,
        filters: {
          ...(state?.filters ?? {}),
          cities: action.payload,
        },
      };
    case VenuesReducerActionType.updateFilters:
      return {
        ...state,
        filters: {
          ...(state?.filters ?? {}),
          ...action.payload,
        },
      };
    case VenuesReducerActionType.updateMetadata:
      return {
        ...state,
        metadata: {
          ...(state?.metadata ?? {}),
          ...action.payload,
        },
      };
    case VenuesReducerActionType.clear:
      return {};
    default:
      return state;
  }
};
