/* eslint-disable @typescript-eslint/no-explicit-any */

import { IVenue } from '@/types/cms';

export interface IVenuesFilters {
  query?: string;
  states?: string[];
  cities?: string[];
}

export interface IMetadata {
  nbHits: number;
  nbPages: number;
  currentPage: number;
  perPage: number;
}

// -- Context

export interface IVenuesContextData {
  venues?: IVenue[];
  filters?: IVenuesFilters;
  metadata?: IMetadata;
}

export enum VenuesReducerActionType {
  updateCities = 'updateCities',
  updateFilters = 'updateFilters',
  updateQuery = 'updateQuery',
  updateStates = 'updateStates',
  updateVenues = 'updateVenues',
  updateMetadata = 'updateMetadata',
  clear = 'clear',
}

export interface IVenuesReducerAction {
  type: VenuesReducerActionType;
  payload?: any;
}
