'use client';

import { SyntheticEvent, useContext, useMemo } from 'react';
import { faChevronDown, faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

import { Context } from './Context';

import { Button, ButtonType } from '@lamesarv-sdk/components';
import { SearchParamAction, useCustomSearchParams } from '@lamesarv-sdk/hooks';
import { getFacetCode } from '@lamesarv-sdk/tools';

import { PropsWithClasses } from '@lamesarv-sdk/types';
import { VenuesReducerActionType } from './Types';

export type IVenueFilter = { label: string; value: string };

// --

export interface IEventSearchProps {
  placeholder?: string;
}

export const EventSearch = (props: IEventSearchProps) => {
  const { context, dispatch } = useContext(Context);

  const handleSearch = async (e: SyntheticEvent) => {
    e.preventDefault();

    const value = ((e.target as HTMLFormElement).query as HTMLInputElement).value;

    dispatch({
      type: VenuesReducerActionType.updateQuery,
      payload: value,
    });
  };

  return (
    <form className="relative w-full" onSubmit={handleSearch}>
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-200"
      />
      <input
        type="search"
        name="query"
        defaultValue={context?.filters?.query}
        placeholder={props.placeholder ?? 'Search'}
        className="w-full py-2 px-4 pl-8 border border-gray-200 rounded-sm focus:ring-gray-400"
      />
      <div className="absolute right-1.5 top-1/2 -translate-y-1/2">
        <Button
          type={ButtonType.flat}
          htmlType="submit"
          title="Search"
          className="bg-neutral-900 text-white py-1.5 px-4 rounded-sm hover:bg-neutral-800 focus:outline-none"
        />
      </div>
    </form>
  );
};

export interface IEventFilterProps extends PropsWithClasses {
  title: string;
  items: IVenueFilter[];
  itemsCurrent: string[];
  callbackSelect: (values: string[]) => void;
}

export const EventFilter = (props: IEventFilterProps) => {
  const handleSelect = (e: SyntheticEvent) => {
    const checked = (e.target as HTMLInputElement).checked;
    const value = (e.target as HTMLInputElement).value;

    if (checked) {
      props.callbackSelect([...props.itemsCurrent, value]);
    } else {
      props.callbackSelect(props.itemsCurrent.filter((item) => item !== value));
    }
  };

  return (
    <Popover className={twMerge('md:relative border border-gray-200 rounded-sm py-2.5 px-3 bg-white', props.className)}>
      <PopoverButton
        className={twMerge(
          'w-full flex flex-row gap-1 items-center justify-between text-sm font-medium focus:outline-none',
          props.classInput,
        )}
      >
        <span className={props.classTitle}>{props.title}</span>
        <FontAwesomeIcon icon={faChevronDown} className={twMerge('w-4 h-4 text-gray-300', props.classIcon)} />
        {props.itemsCurrent.length > 0 && (
          <div className="absolute -top-2 -right-2 leading-none text-xs text-white bg-red-600 py-1 px-2 rounded-full">
            {props.itemsCurrent.length}
          </div>
        )}
      </PopoverButton>
      <PopoverPanel
        className={twMerge(
          'absolute origin-top-left left-4 right-4 z-10 mt-5 p-4 bg-white border border-gray-200 shadow-xl rounded-sm focus:outline-none',
          'md:origin-top-right md:left-auto md:right-0 md:w-fit md:min-w-full',
          'transition data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in',
        )}
      >
        <div className="flex flex-col gap-2">
          {!props.items.length && (
            <span className="text-xs font-medium text-gray-300 whitespace-nowrap">No items available</span>
          )}
          {props.items.map((item) => (
            <label key={item.value} className="flex flex-row gap-2 items-center">
              <input
                type="checkbox"
                value={item.value}
                checked={props.itemsCurrent.includes(item.value)}
                onChange={handleSelect}
                className="h-8 w-8 rounded border-gray-300 text-indigo-600 md:h-4 md:w-4 focus:ring-indigo-500"
              />
              <span className="text-sm font-medium text-gray-900 capitalize whitespace-nowrap">{item.label}</span>
            </label>
          ))}
        </div>
      </PopoverPanel>
    </Popover>
  );
};

export interface IEventFilterPillProps {
  prefix: string;
  title: string;
  value: string;
  callbackRemove: (value: string) => void;
}

export const EventFilterPill = (props: IEventFilterPillProps) => {
  return (
    <div className="relative flex flex-row items-center rounded-full border border-gray-300 bg-white">
      <div className="text-xs text-gray-400 bg-gray-200 py-1.5 pl-3 pr-2 rounded-l-full">{props.prefix}</div>
      <div className="text-xs py-1.5 px-2">{props.title}</div>
      <FontAwesomeIcon
        icon={faXmark}
        className="w-4 h-4 pr-3 cursor-pointer"
        onClick={() => props.callbackRemove(props.value)}
      />
    </div>
  );
};

// --

export interface IEventsListHeaderProps extends PropsWithClasses {
  displaySearch?: boolean;
  displayFilterState?: boolean;
  displayFilterCity?: boolean;
}

export const Header = (props: IEventsListHeaderProps) => {
  const { context, dispatch, getStatesList, getCitiesList } = useContext(Context);

  const { searchParams, updateSearchParams } = useCustomSearchParams();

  const { states, cities } = useMemo(() => {
    const _states = getStatesList().map((state) => ({ label: state, value: getFacetCode(state) }));
    const _cities = getCitiesList(context?.filters?.states).map((city) => ({ label: city, value: getFacetCode(city) }));

    return {
      states: _states,
      cities: _cities,
    };
  }, [context?.filters, context?.venues]);

  const getFilterLabel = (filters: IVenueFilter[], value: string) => {
    return filters.find((item) => item.value === value)?.label;
  };

  const displayFiltersList =
    (context?.filters?.states?.length > 0 && props.displayFilterState) ||
    (context?.filters?.cities?.length > 0 && props.displayFilterCity);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 justify-between items-center md:flex-row md:gap-4">
        {props.displaySearch && <EventSearch placeholder="Search Venues" />}
        {(props.displayFilterState || props.displayFilterCity) && (
          <PopoverGroup className="w-full flex flex-row gap-2 items-center md:gap-4 md:w-auto">
            {props.displayFilterState && (
              <EventFilter
                title="States"
                items={states}
                itemsCurrent={context.filters.states}
                callbackSelect={(newSelectedStates: string[]) => {
                  updateSearchParams([
                    {
                      action: newSelectedStates.length ? SearchParamAction.add : SearchParamAction.remove,
                      key: 'state',
                      value: newSelectedStates.join(','),
                    },
                    { action: SearchParamAction.remove, key: 'city', value: '' },
                    { action: SearchParamAction.remove, key: 'venue', value: '' },
                  ]);
                  dispatch({
                    type: VenuesReducerActionType.updateFilters,
                    payload: {
                      states: newSelectedStates,
                      cities: [],
                    },
                  });
                }}
                className="w-full md:w-auto md:min-w-36"
              />
            )}
            {props.displayFilterCity && (
              <EventFilter
                title="Cities"
                items={cities}
                itemsCurrent={context.filters.cities}
                callbackSelect={(newSelectedCities: string[]) => {
                  updateSearchParams([
                    { action: SearchParamAction.add, key: 'state', value: searchParams.get('state') },
                    {
                      action: newSelectedCities.length ? SearchParamAction.add : SearchParamAction.remove,
                      key: 'city',
                      value: newSelectedCities.join(','),
                    },
                    { action: SearchParamAction.remove, key: 'venue', value: '' },
                  ]);
                  dispatch({
                    type: VenuesReducerActionType.updateFilters,
                    payload: {
                      cities: newSelectedCities,
                    },
                  });
                }}
                className="w-full md:w-auto md:min-w-36"
              />
            )}
          </PopoverGroup>
        )}
      </div>
      {displayFiltersList && (
        <div className="flex flex-row flex-wrap gap-2 items-center">
          <span className="text-sm text-gray-500 font-medium">Filters:</span>
          {props.displayFilterState &&
            context?.filters?.states?.map((state) => (
              <EventFilterPill
                key={state.toLowerCase()}
                prefix="State"
                title={getFilterLabel(states, state)}
                value={state}
                callbackRemove={(value: string) => {
                  const newSelectedStates = context?.filters?.states?.filter((item) => item !== value);
                  updateSearchParams([
                    {
                      action: newSelectedStates.length ? SearchParamAction.add : SearchParamAction.remove,
                      key: 'state',
                      value: newSelectedStates.join(','),
                    },
                    { action: SearchParamAction.remove, key: 'city', value: '' },
                    { action: SearchParamAction.remove, key: 'venue', value: '' },
                  ]);
                  dispatch({
                    type: VenuesReducerActionType.updateFilters,
                    payload: {
                      states: newSelectedStates,
                      cities: [],
                    },
                  });
                }}
              />
            ))}
          {props.displayFilterCity &&
            context?.filters?.cities?.map((city) => (
              <EventFilterPill
                key={city.toLowerCase()}
                prefix="City"
                title={getFilterLabel(cities, city)}
                value={city}
                callbackRemove={(value: string) => {
                  const newSelectedCities = context?.filters?.cities?.filter((item) => item !== value);
                  updateSearchParams([
                    { action: SearchParamAction.add, key: 'state', value: searchParams.get('state') },
                    {
                      action: newSelectedCities.length ? SearchParamAction.add : SearchParamAction.remove,
                      key: 'city',
                      value: newSelectedCities.join(','),
                    },
                    { action: SearchParamAction.remove, key: 'venue', value: '' },
                  ]);
                  dispatch({
                    type: VenuesReducerActionType.updateFilters,
                    payload: {
                      cities: newSelectedCities,
                    },
                  });
                }}
              />
            ))}
        </div>
      )}
    </div>
  );
};
