'use client';

import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Provider } from './Context';

import { getFacetCode } from '@lamesarv-sdk/tools';
import { useCustomSearchParams } from '@lamesarv-sdk/hooks';

import { Handler } from './Handler';
import { Header } from './Header';
import { GridBase } from './GridBase';

import { IComponentBase, IGridBase, PropsWithClasses } from '@lamesarv-sdk/types';
import { IVenuesContextData } from './Types';

// --

export interface IComponentVenuesList extends IComponentBase, IGridBase, PropsWithClasses {
  filterByState?: string;
  filterByCity?: string;
  displaySearch?: boolean;
  displayFilterState?: boolean;
  displayFilterCity?: boolean;
  classHeader?: string;
}

export const VenuesList = (props: IComponentVenuesList) => {
  const { searchParams } = useCustomSearchParams();

  const [contextData, setContextData] = useState<IVenuesContextData | undefined>(undefined);

  const getSelections = (propsList?: string, urlParams?: string) => {
    const selection = new Set<string>();

    propsList
      ?.split(',')
      ?.map((item) => item.trim())
      ?.filter(Boolean)
      ?.forEach((item) => selection.add(getFacetCode(item)));

    urlParams
      ?.split(',')
      ?.map((item) => item.trim())
      ?.filter(Boolean)
      ?.forEach((item) => selection.add(getFacetCode(item)));

    return Array.from(selection);
  };

  const selectedStates = getSelections(props.filterByState, searchParams.get('state'));
  const selectedCities = getSelections(props.filterByCity, searchParams.get('city'));

  const query = searchParams.get('query') || '';

  const getContextData = async () => {
    const filters = {
      query,
      states: selectedStates,
      cities: selectedCities,
    };

    const _contextData = {
      filters,
    };

    setContextData(_contextData);
  };

  useEffect(() => {
    getContextData();
  }, []);

  return (
    <div className={twMerge(props.className)}>
      {contextData !== undefined && (
        <Provider {...contextData}>
          <Handler />
          <div className="max-w-7xl space-y-12 mx-auto p-4 md:p-8">
            <Header {...props} />
          </div>
          <div className="max-w-7xl space-y-12 mx-auto p-4 md:p-8">
            <GridBase {...props} />
          </div>
        </Provider>
      )}
    </div>
  );
};
