'use client';

import { createContext, Dispatch, PropsWithChildren, useMemo, useReducer } from 'react';

import { venuesReducer } from './Reducer';
import { IVenuesContextData, IVenuesReducerAction } from './Types';
import { getFacetCode } from '@lamesarv-sdk/tools';

export interface IVenueContext {
  context: IVenuesContextData;
  dispatch: Dispatch<IVenuesReducerAction>;
  getStatesList: () => string[];
  getCitiesList: (states: string[]) => string[];
}

export const Context = createContext<IVenueContext>({} as IVenueContext);

export const Provider = ({ children, ...props }: PropsWithChildren<IVenuesContextData>) => {
  const [context, dispatch] = useReducer(venuesReducer, props);

  const getStatesList = (): string[] => {
    return Array.from(new Set(context?.venues?.map((venue) => venue.state)));
  };

  const getCitiesList = (states: string[]): string[] => {
    return Array.from(
      new Set(
        context?.venues?.filter((venue) => states.includes(getFacetCode(venue.state)))?.map((venue) => venue.city),
      ),
    );
  };

  const providerValue = useMemo(
    () => ({ context, dispatch, getStatesList, getCitiesList }),
    [context, dispatch, getStatesList, getCitiesList],
  );

  return <Context.Provider value={providerValue}>{children}</Context.Provider>;
};
