'use client';

import { useContext } from 'react';
import { twMerge } from 'tailwind-merge';

import { Context } from './Context';

import { Grid } from '@lamesarv-sdk/components';
import { GridItem } from './GridItem';

import { IGridBase, PropsWithClasses } from '@lamesarv-sdk/types';

// --

export interface IGridBaseProps extends IGridBase, PropsWithClasses {
  // ...
}

export const GridBase = (props: IGridBaseProps) => {
  const { context } = useContext(Context);

  return (
    <div className={twMerge('state', props.className)}>
      {!context?.venues?.length && (
        <div className="flex flex-row justify-center items-center">
          <span className="text-gray-300">No venues found</span>
        </div>
      )}
      <Grid
        items={
          context?.venues?.map((item) => {
            return <GridItem key={item.slug} {...item} />;
          }) ?? []
        }
        baseCols={props.baseCols || 1}
        smCols={props.smCols || 2}
        mdCols={props.mdCols || 2}
        lgCols={props.lgCols || 3}
        xlCols={props.xlCols || 3}
        gap={props.gap || 6}
      />
    </div>
  );
};
